<template>
  <div v-if="message.type === 'answer'" class="answer">
    <p v-for="para in message.text" :key="para" v-html="renderlinks(para)"></p>
    <div
      class="feedback-buttons"
      v-if="message.showFeedbackButtons && !message.disliked && !message.liked"
    >
      <div @click="likeMessage(message.id)" class="like-button">
        <i class="fa-regular fa-thumbs-up"></i>
      </div>
      <div @click="dislikeMessage(message.id)" class="dislike-button">
        <i class="fa-regular fa-thumbs-down"></i>
      </div>
    </div>
    <div class="feedback-buttons" v-else>
      <div
        @click="revertFeedback(message.id)"
        class="liked-button"
        :class="message.liked ? 'd-block' : 'd-none'"
      >
        <i class="fas fa-thumbs-up"></i>
      </div>
      <div
        @click="revertFeedback(message.id)"
        class="disliked-button"
        :class="message.disliked ? 'd-block' : 'd-none'"
      >
        <i class="fas fa-thumbs-down"></i>
      </div>
    </div>
  </div>
  <div v-if="message.type === 'question'" class="question">
    {{ message.text[0] }}
  </div>
  <div v-if="loading && message.type === 'skeleton'" class="skeleton">
    <span class="dot" style="animation-delay: 0.1s"></span>
    <span class="dot" style="animation-delay: 0.2s"></span>
    <span class="dot" style="animation-delay: 0.3s"></span>
  </div>
  <div
    v-if="message.type === 'question' || message.type === 'answer'"
    :class="message.type === 'question' ? 'text-end' : 'text-start'"
    class="timestamp"
  >
    {{ getFormattedTime(message.time) }}
  </div>
</template>

<script>
export default {
  props: {
    message: Object,
    loading: Boolean,
  },
  methods: {
    getFormattedTime(time) {
      return new Date(time).toLocaleTimeString();
    },
    likeMessage(id) {
      this.$emit("msgLiked", { msgID: id });
    },
    dislikeMessage(id) {
      this.$emit("msgDisliked", { msgID: id });
    },
    revertFeedback(id) {
      this.$emit("feedbackReverted", { msgID: id });
    },
    renderlinks(text) {
      var replacedText, replacePattern1, replacePattern2, replacePattern3;

      replacePattern1 =
        /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim;
      replacedText = text.replace(replacePattern1, function (match, url) {
        var parts = url.split("/");
        var domain = decodeURIComponent(parts.at(-1));
        return '<a href="' + url + '" target="_blank">' + domain + "</a>";
      });

      replacePattern2 = /(^|[^/])(www\.[\S]+(\b|$))/gim;
      replacedText = replacedText.replace(
        replacePattern2,
        '$1<a href="http://$2" target="_blank">$2</a>'
      );

      replacePattern3 = /(([a-zA-Z0-9\-_.])+@[a-zA-Z_]+?(\.[a-zA-Z]{2,6})+)/gim;
      replacedText = replacedText.replace(
        replacePattern3,
        '<a href="mailto:$1">$1</a>'
      );
      return replacedText;
    },
  },
};
</script>

<style scoped>
.answer {
  padding: 0.25rem 0.5rem;
  border-radius: 0.313rem;
  background-color: #b3d9ee;
  align-self: flex-start;
  overflow: hidden;
  max-width: 85%;
  word-wrap: break-word;
  text-align: start;
}

.question {
  padding: 0.25rem 0.5rem;
  border-radius: 0.313rem;
  background-color: #e0e0e0;
  align-self: flex-end;
  overflow: hidden;
  max-width: 85%;
  word-wrap: break-word;
  text-align: start;
}

.skeleton {
  width: 14.375rem;
  padding-left: 0.2rem;
  height: 1rem;
  display: flex;
  align-items: center;
}

.dot {
  width: 0.5rem;
  height: 0.5rem;
  background-color: #333;
  border-radius: 50%;
  margin-right: 0.5rem;
  animation: typing-dot 1s infinite;
}

@keyframes typing-dot {
  0%,
  20%,
  80%,
  100% {
    transform: translateY(0) scale(1.2) rotate(0deg);
    opacity: 1;
  }
  40% {
    transform: translateY(-0.8rem) scale(0.8) rotate(-180deg);
    opacity: 0.7;
  }
}

.timestamp {
  font-size: 0.8em;
  color: #777;
  margin-top: 0.25rem;
}

.like-button,
.dislike-button {
  margin: 0.2rem;
  cursor: pointer;
  padding: 0rem 0rem;
  border: none;
  border-radius: 0.313rem;
  color: black;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}
.like-button {
  margin-left: 0.425rem;
}
.dislike-button {
  transform: rotateY(180deg);
}

.dislike-button i:hover {
  color: rgb(199, 23, 23);
}
.like-button i:hover {
  color: #388e3c;
}

.liked-button,
.disliked-button {
  margin: 0.1rem 0.4rem;
  cursor: pointer;
  padding: 0rem 0rem;
  border: none;
  border-radius: 0.313rem;
  color: black;
  font-size: 1rem;
}

.disliked-button {
  transform: rotateY(180deg);
}

.feedback-buttons {
  background-color: white;
  display: flex;
  position: absolute;
  right: 3rem;
  margin-top: -0.5rem;
  border-radius: 0.325rem;
  box-shadow: rgba(0, 0, 0, 0.05) 0rem 0rem 0rem 0.125rem;
}
</style>
