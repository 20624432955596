/* eslint-disable  @typescript-eslint/no-explicit-any */
import DataService from "../data/DataService";

class CompanyInfoData {
  async fetchInfo(clientId: string): Promise<any> {
    return DataService.get("api/tax_profile/company", [
      { key: "clientid", value: clientId },
    ]);
  }
}
export default new CompanyInfoData();
