import {
  UserManager,
  WebStorageStateStore,
  User,
  UserManagerSettings,
  Log,
} from "oidc-client-ts";

Log.setLogger(console);

class AuthService {
  private userMgr: UserManager;

  constructor() {
    const settings: UserManagerSettings = {
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      authority: process.env.VUE_APP_ASURE_IDENTITY_DOMAIN || "",
      client_id: process.env.VUE_APP_CLIENT_ID || "",
      redirect_uri: `${process.env.VUE_APP_REDIRECT_URL}/callback`,
      response_type: "code",
      scope: "openid profile email asure-tax",
      post_logout_redirect_uri: `${process.env.VUE_APP_REDIRECT_URL}/`,
      filterProtocolClaims: true,
      clockSkewInSeconds: 120,
      loadUserInfo: true,
      metadataUrl: `${process.env.VUE_APP_ASURE_IDENTITY_DOMAIN}/oauth2/token/.well-known/openid-configuration`,
    };

    this.userMgr = new UserManager(settings);
  }

  public async user(): Promise<User | null> {
    return await this.userMgr.getUser();
  }

  public signIn(): Promise<void> {
    return this.userMgr.signinRedirect();
  }

  public signOut(): Promise<void> {
    return this.userMgr.signoutRedirect();
  }

  public async siginRedirectCallback(): Promise<User> {
    const tempMgr = new UserManager({
      authority: process.env.VUE_APP_ASURE_IDENTITY_DOMAIN || "",
      client_id: process.env.VUE_APP_CLIENT_ID || "",
      redirect_uri: `${process.env.VUE_APP_REDIRECT_URL}/callback`,
      response_mode: "query",
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      metadataUrl: `${process.env.VUE_APP_ASURE_IDENTITY_DOMAIN}/oauth2/token/.well-known/openid-configuration`,
    });

    return tempMgr.signinRedirectCallback();
  }
}

export const asureIdentity = new AuthService();
