import { provideIntlService } from "@progress/kendo-vue-intl";
import { ZonedDate } from "@progress/kendo-date-math";
import "@progress/kendo-date-math/tz/America/Los_Angeles";

class DateUtils {
  public formatObject(dateObj: any) {
    return provideIntlService(this).formatDate(dateObj, "MM/dd/yy");
  }
  public getZonedDate(dateObj: any) {
    return ZonedDate.fromUTCDate(dateObj, "America/Los_Angeles");
  }
}

export default new DateUtils();
