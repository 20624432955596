import { createApp } from "vue";
import { createPinia } from "pinia";
import router from "./router";
import { emitter } from "./bus";
import { createI18n, I18nOptions } from "vue-i18n";
import VueSweetalert2 from "vue-sweetalert2";
import App from "./App.vue";
import VueCookies from "vue-cookies";
import Vue3TouchEvents from "vue3-touch-events";

const numberFormats: I18nOptions["numberFormats"] = {
  "en-US": {
    currency: {
      style: "currency",
      currency: "USD",
      notation: "standard",
    },
    decimal: {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: "percent",
      useGrouping: false,
    },
  },
};
const datetimeFormats: I18nOptions["datetimeFormats"] = {
  "en-US": {
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    SHORT: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "short",
      hour: "numeric",
      minute: "numeric",
    },
  },
};

const i18n = createI18n({
  numberFormats,
  datetimeFormats,
});

declare module "@vue/runtime-core" {
  export interface ComponentCustomProperties {
    emitter: typeof emitter;
  }
}

const app = createApp(App);
app.use(createPinia());
app.use(router);
app.use(i18n);
app.config.globalProperties.emitter = emitter;
app.use(VueSweetalert2);
app.use(VueCookies);
app.use(Vue3TouchEvents);
app.directive("click-outside", {
  mounted(el, binding, vnode) {
    el.clickOutsideEvent = function (event: { target: any }) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event, el);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unmounted(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});
router.isReady().then(() => {
  app.mount("#app");
});
