import { defineStore } from "pinia";

export const useMenuStore = defineStore({
  id: "menu",
  state: () => ({
    userPrefsQEOptions: ["No", "Top", "Middle", "Bottom"],
    userPrefsChatBotOptions: ["No", "Yes"],
    userPrefsQE: "No",
    userPrefsChatBot: "Yes",
    userPayLookbackDays: 45,
    checked: false,
    screenWidth: 0,
    screenType: "mobile",
    userPrefs: {},
    defaultMobileLogo: require("@/assets/img/NavLogo.svg"),
    defaultDesktopLogo: require("@/assets/img/asurelogo_black.svg"),
    desktopLogo: null,
    mobileLogo: null,
    showClientIdEditOption: true,
  }),
  getters: {
    isChecked: (state) => state.checked,
  },
  actions: {
    toggleMenu() {
      if (this.screenWidth < 992) {
        this.checked = !this.checked;
      }
    },
  },
});
