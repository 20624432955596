import { defineStore } from "pinia";

export const useErrorModalStore = defineStore({
  id: "errorModal",
  state: () => ({
    showModal: false,
    errorMessage: "",
  }),
  actions: {},
});
