import { defineStore } from "pinia";

export const useLogStore = defineStore({
  id: "logs",
  state: () => ({
    type: {
      SUCCESS: "success",
      ERROR: "error",
      WARNING: "warning",
      INFO: "info",
      NONE: "none",
    },
    successLogs: [],
    success: false,
    errorLogs: [],
    error: false,
    warningLogs: [],
    warning: false,
    infoLogs: [],
    info: false,
    noneLogs: [],
    none: false,
  }),
  actions: {
    addLog(log: string, logType: string) {
      if (logType.includes(this.type.SUCCESS)) {
        if (!this.successLogs.includes(log as never)) {
          this.successLogs.push(log as never);
          if (!this.success) {
            this.slowToggle(logType);
          }
        }
      } else if (logType.match(this.type.ERROR)) {
        if (!this.errorLogs.includes(log as never)) {
          this.errorLogs.push(log as never);
          if (!this.error) {
            this.slowToggle(logType);
          }
        }
      } else if (logType.match(this.type.WARNING)) {
        if (!this.warningLogs.includes(log as never)) {
          this.warningLogs.push(log as never);
          if (!this.warning) {
            this.slowToggle(logType);
          }
        }
      } else if (logType.match(this.type.INFO)) {
        if (!this.infoLogs.includes(log as never)) {
          this.infoLogs.push(log as never);
          if (!this.info) {
            this.slowToggle(logType);
          }
        }
      } else {
        if (!this.noneLogs.includes(log as never)) {
          this.noneLogs.push(log as never);
          if (!this.none) {
            this.slowToggle(logType);
          }
        }
      }
    },

    slowToggle(logType: string) {
      if (logType.match(this.type.SUCCESS)) {
        if (this.success) {
          setTimeout(() => {
            this.successLogs.shift();
            if (this.successLogs.length == 0) {
              this.success = false;
            } else {
              this.slowToggle(logType);
            }
          }, 1500);
        } else {
          this.success = true;
          this.slowToggle(logType);
        }
      } else if (logType.match(this.type.ERROR)) {
        if (this.error) {
          setTimeout(() => {
            this.errorLogs.shift();
            if (this.errorLogs.length == 0) {
              this.error = false;
            } else {
              this.slowToggle(logType);
            }
          }, 1500);
        } else {
          this.error = true;
          this.slowToggle(logType);
        }
      } else if (logType.match(this.type.WARNING)) {
        if (this.warning) {
          setTimeout(() => {
            this.warningLogs.shift();
            if (this.warningLogs.length == 0) {
              this.warning = false;
            } else {
              this.slowToggle(logType);
            }
          }, 1500);
        } else {
          this.warning = true;
          this.slowToggle(logType);
        }
      } else if (logType.match(this.type.INFO)) {
        if (this.info) {
          setTimeout(() => {
            this.infoLogs.shift();
            if (this.infoLogs.length == 0) {
              this.info = false;
            } else {
              this.slowToggle(logType);
            }
          }, 1500);
        } else {
          this.info = true;
          this.slowToggle(logType);
        }
      } else {
        if (this.none) {
          setTimeout(() => {
            this.noneLogs.shift();
            if (this.noneLogs.length == 0) {
              this.none = false;
            } else {
              this.slowToggle(logType);
            }
          }, 1500);
        } else {
          this.none = true;
          this.slowToggle(logType);
        }
      }
    },

    closeLogs(logType: string) {
      if (logType.includes(this.type.SUCCESS)) {
        this.successLogs = [];
        this.success = false;
      } else if (logType.match(this.type.ERROR)) {
        this.errorLogs = [];
        this.error = false;
      } else if (logType.match(this.type.WARNING)) {
        this.warningLogs = [];
        this.warning = false;
      } else if (logType.match(this.type.INFO)) {
        this.infoLogs = [];
        this.info = false;
      } else {
        this.noneLogs = [];
        this.none = false;
      }
    },
  },
});
