import moment from "moment";

class GlbFunction {
  ccyfmt = new Intl.NumberFormat(`en-US`, {
    currency: `USD`,
    style: "currency",
  });

  currencyFormat(value: any) {
    return value < 0
      ? "(" + this.ccyfmt.format(value * -1) + ")"
      : this.ccyfmt.format(value);
  }

  dateFormat(date?: string) {
    return !date ? "" : moment.utc(date).format("MM/DD/YY");
  }

  reportName(params: string[] = []) {
    if (params.length > 0) {
      return (
        params.join("_") +
        "_" +
        new Date().toISOString().split("T")[0].replace(/-/g, "")
      );
    } else {
      return "";
    }
  }
}
export default new GlbFunction();
