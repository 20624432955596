import DataService from "../data/DataService";
import http from "../data/http-common";

class ChatBotData {
  async postQuestion(urlPart: string, body: any): Promise<any> {
    return DataService.post(`prod1/${urlPart}`, body, [], http.api3Client);
  }
}

export default new ChatBotData();
