/* eslint-disable  @typescript-eslint/no-explicit-any */
import { defineStore } from "pinia";
import { RouteLocation } from "vue-router";
import { asureIdentity } from "../services/auth/asure-identity";
import { User } from "oidc-client-ts";
const roleMap = {
  TXAP: "TaxProcessor",
  TXCL: "TaxClient",
  TXAD: "TaxAdmin",
  UNKW: "Unknown",
};
type State = {
  currentUser: User | null;
  currentClientObject: any;
  currentComponent: string | null;
  redirectRoute: Partial<RouteLocation> | null;
};

type Getters = {
  isAuthenticated(): boolean;
};

type Actions = {
  loadUser(): any;
  loadClient(): any;
  clearUser(): void;
  getRole(group: unknown): string;
  saveCurrentClientObject(client: any): void;
  loadCurrentClientObject(): void;
  loadCurrentUser(): void;
  clearCurrentClientObject(): void;
  saveCurrentUser(user: any): void;
};

export const useAuthStore = defineStore<"auth", State, Getters, Actions>(
  "auth",
  {
    state() {
      return {
        currentUser: null,
        userData: {
          id_token: "",
          access_token: "",
          refresh_token: "",
          token_type: "",
          scope: "",
          session_state: "",
        },
        currentClientObject: {
          ClientID: "",
          LegalName: "",
          FEIN: "",
          PayrollID: "",
          Status: "",
          profileName: "",
          firstName: "",
          lastName: "",
          profilePic: "",
          email: "",
          tax_client_ids: [],
          tax_processor_id: "",
          role: "",
          groups: [],
        },
        currentComponent: "dashboard",
        redirectRoute: null,
      };
    },
    getters: {
      isAuthenticated() {
        const cUser: any = localStorage.getItem("currentUser");
        return cUser != null ? true : false;
      },
      roleMap() {
        return roleMap;
      },
    },
    actions: {
      loadUser() {
        return (async () => {
          this.currentUser = await asureIdentity.user();
          const ClientID: any = this.currentUser?.profile.tax_client_ids;
          if (ClientID) {
            this.currentClientObject.ClientID = Array.isArray(ClientID)
              ? ClientID[0]
              : ClientID;
          }
          this.currentClientObject.email = this.currentUser?.profile.email;
          this.currentClientObject.role = this.getRole(
            this.currentUser?.profile?.groups
          );
          this.currentClientObject.tax_client_ids = ClientID;
          this.currentClientObject.tax_processor_id =
            this.currentUser?.profile.tax_processor_id;
          this.currentClientObject.profileName = this.currentUser?.profile.sub;
          this.currentClientObject.profilePic =
            this.currentUser?.profile.picture;
          this.currentClientObject.firstName =
            this.currentUser?.profile.given_name;
          this.currentClientObject.lastName =
            this.currentUser?.profile.family_name;
          this.currentClientObject.groups = this.currentUser?.profile?.groups;
          return this.currentUser;
        })();
      },
      loadClient() {
        return (async () => {
          this.currentUser = await asureIdentity.user();
          const ClientID: any = this.currentUser?.profile.tax_client_ids;
          if (ClientID) {
            this.currentClientObject.ClientID = Array.isArray(ClientID)
              ? ClientID[0]
              : ClientID;
            this.currentClientObject.email = this.currentUser?.profile.email;
            this.currentClientObject.role = this.getRole(
              this.currentUser?.profile?.groups
            );
            this.currentClientObject.profileName =
              this.currentUser?.profile.sub;
            this.currentClientObject.profilePic =
              this.currentUser?.profile.picture;
            this.currentClientObject.tax_client_ids = ClientID;
            this.currentClientObject.tax_processor_id =
              this.currentUser?.profile.tax_processor_id;
          }
          return this.currentClientObject;
        })();
      },
      clearUser() {
        localStorage.removeItem("currentUser");
        this.currentUser = null;
      },
      async saveCurrentClientObject(client: any) {
        localStorage.setItem("currentClientObject", JSON.stringify(client));
      },
      loadCurrentClientObject() {
        const clientItem = JSON.parse(
          localStorage.getItem("currentClientObject") || "null"
        ) as any;

        if (clientItem !== undefined && clientItem !== null) {
          this.currentClientObject = clientItem;
        }
      },
      loadCurrentUser() {
        const currentUser = JSON.parse(
          localStorage.getItem("currentUser") || "null"
        ) as any;

        if (currentUser !== undefined && currentUser !== null) {
          this.currentUser = currentUser;
        }
      },
      clearCurrentClientObject() {
        localStorage.removeItem("currentClientObject");
        this.currentClientObject = null;
      },
      saveCurrentUser(user: any) {
        localStorage.setItem("currentUser", JSON.stringify(user));
      },
      getRole(group: string[]) {
        return group
          ? group.includes(roleMap.TXAD)
            ? roleMap.TXAD
            : group.includes(roleMap.TXAP)
            ? roleMap.TXAP
            : group.includes(roleMap.TXCL)
            ? roleMap.TXCL
            : roleMap.UNKW
          : roleMap.UNKW;
      },
    },
  }
);
