/* eslint-disable  @typescript-eslint/no-explicit-any */
import DataService from "../data/DataService";

class HeaderData {
  async fetchData(urlpart: string, processorId: string): Promise<any> {
    if (processorId) {
      return DataService.get(`api/${urlpart}`, [
        { key: "processorid", value: processorId },
      ]);
    } else {
      return DataService.get(`api/${urlpart}`);
    }
  }

  async fetchDataClient(urlpart: string, clientids = "") {
    if (clientids !== null && clientids !== "") {
      return DataService.get(`api/${urlpart}`, [
        { key: "clientids", value: clientids },
      ]);
    } else {
      return DataService.get(`api/${urlpart}`);
    }
  }

  async fetchMultiClientData(urlpart: string, processorId = "", group = "") {
    if (processorId && group) {
      return DataService.get(`api/${urlpart}`, [
        { key: "processorid", value: processorId },
        { key: "group", value: group },
      ]);
    } else {
      return DataService.get(`api/${urlpart}`);
    }
  }

  async fetchInfo(urlpart: string): Promise<any> {
    return DataService.get(`api/${urlpart}`);
  }

  async fetchProcessorList(urlpart: string): Promise<any> {
    return DataService.get(`api/${urlpart}`);
  }
  async fetchProcessorLogo(urlpart: string, processorId: string): Promise<any> {
    if (processorId) {
      return DataService.get(`api/${urlpart}`, [
        { key: "processorid", value: processorId },
      ]);
    } else {
      return DataService.get(`api/${urlpart}`);
    }
  }
  async fetchClientIdEditPref(
    urlpart: string,
    clientId: any = null,
    processorId: any = null
  ): Promise<any> {
    return DataService.get(`api/${urlpart}`, [
      {
        key: "clientId",
        value: clientId,
      },
      {
        key: "processorId",
        value: processorId,
      },
    ]);
  }
}
export default new HeaderData();
