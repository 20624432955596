/* eslint-disable  @typescript-eslint/no-explicit-any */
import { useAuthStore } from "../../stores/auth";

export class ClientObjectEmitter {
  _emitter: any;
  authStore = useAuthStore();

  constructor(emitter: any) {
    this._emitter = emitter;
    this.authStore = useAuthStore();
  }

  CreateEvent(emitObject: any, emitterEventName = "ChangeClientData"): void {
    this.authStore.saveCurrentClientObject(emitObject);
    this._emitter.emit(emitterEventName, emitObject);
  }

  ListenToEvent(emitterEventName = "ChangeClientData"): any {
    return {
      emitter: this._emitter,
      eventName: emitterEventName,
      clientObjectStore: this.authStore.currentClientObject,
    };
  }
}
