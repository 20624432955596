/* eslint-disable  @typescript-eslint/no-explicit-any */
import http from "./http-common";
import { AxiosInstance } from "axios";

class DataService {
  generateUrlParam(params: any[]): string {
    let paramsurl = "";
    params.forEach((element) => {
      element.key;
      element.value;
      paramsurl += `${element.key}=${element.value}&`;
    });
    return this.replaceLast(paramsurl, "&", "");
  }

  replaceLast(x: string, y: string, z: string) {
    const a = x.split("");
    a[x.lastIndexOf(y)] = z;
    return a.join("");
  }

  get(
    urlpart: string,
    params: any[] = [{ key: "", value: "" }],
    client: AxiosInstance = http.apiClient
  ): Promise<any> {
    if (params !== null && params.length > 0 && params[0].key !== "") {
      return client.get(`${urlpart}?${this.generateUrlParam(params)}`);
    } else {
      return client.get(urlpart);
    }
  }

  post(
    urlpart: string,
    data: any,
    params: any[] = [{ key: "", value: "" }],
    client: AxiosInstance = http.apiClient
  ): Promise<any> {
    if (params !== null && params.length > 0 && params[0].key !== "") {
      return client.post(`${urlpart}?${this.generateUrlParam(params)}`, data);
    } else {
      return client.post(urlpart, data);
    }
  }

  patch(
    urlpart: string,
    data: any,
    params: any[] = [{ key: "", value: "" }],
    client: AxiosInstance = http.apiClient
  ): Promise<any> {
    if (params !== null && params.length > 0 && params[0].key !== "") {
      return client.patch(`${urlpart}?${this.generateUrlParam(params)}`, data);
    } else {
      return client.patch(urlpart, data);
    }
  }

  put(
    urlpart: string,
    data: any,
    params: any[] = [{ key: "", value: "" }],
    client: AxiosInstance = http.apiClient
  ): Promise<any> {
    if (params !== null && params.length > 0 && params[0].key !== "") {
      return client.put(`${urlpart}?${this.generateUrlParam(params)}`, data);
    } else {
      return client.put(urlpart, data);
    }
  }

  delete(
    urlpart: string,
    data: any,
    params: any[] = [{ key: "", value: "" }],
    client: AxiosInstance = http.apiClient
  ): Promise<any> {
    if (params !== null && params.length > 0 && params[0].key !== "") {
      return client.delete(`${urlpart}?${this.generateUrlParam(params)}`, data);
    } else {
      return client.delete(urlpart, data);
    }
  }
}

export default new DataService();
