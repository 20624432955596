/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosInstance } from "axios";

const access_token: any = () => {
  const user: any = localStorage.getItem("currentUser");
  const token = JSON.parse(user);
  if (token) {
    return token.access_token;
  } else {
    return null;
  }
};

const apiClient: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL1,
  headers: {
    "Content-type": "application/json",
    token: `${access_token() !== null ? access_token() : ""}`,
  },
});

const api2Client: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL2,
  headers: {
    "Content-type": "application/json",
    token: `${access_token() !== null ? access_token() : ""}`,
  },
});

const api3Client: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL3,
  headers: {
    "Content-type": "application/json",
    // token: `${access_token() !== null ? access_token() : ""}`,
  },
});

const api4Client: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL4,
  headers: {
    "Content-type": "application/json",
    token: `${access_token() !== null ? access_token() : ""}`,
  },
});

export default {
  apiClient,
  api2Client,
  api3Client,
  api4Client,
  access_token,
};
