/* eslint-disable  @typescript-eslint/no-explicit-any */
import { ref } from "vue";

export class Header {
  sort: any;
  clientId: string;
  filter: any;
  skip: number;
  take: number;
  popupData: any[];
  modalTitle: string;
  columns: any[];
  summaryData: any;
  mcolumns: any[];
  rows: any[];
  staticBackdrop: any;
  clientModal: any;
  searchWord: string;
  statusList: string[];
  statusValue: string;

  constructor() {
    this.sort = [{ field: "Status", dir: "asc" }];
    this.statusList = ["All", "Active", "Inactive"];
    this.statusValue = "Active";
    this.clientId = "";
    this.filter = null;
    this.skip = 0;
    this.take = 10;
    this.popupData = [];
    this.columns = [];
    this.summaryData = [];
    this.modalTitle = "Client List";
    this.rows = [];
    this.searchWord = "";
    this.staticBackdrop = ref(false);
    this.mcolumns = [
      {
        field: "Payroll ID",
        title: "Payroll ID",
        mobileHeader: true,
        percentage: 6,
      },
      {
        field: "Legal Name",
        title: "Legal Name",
        mobileHeader: true,
        headerCell: "headerTemplate",
        percentage: 17,
      },
      {
        field: "FEIN",
        title: "FEIN",
        percentage: 9,
      },
      {
        field: "Status",
        title: "Status",
        percentage: 8,
      },
      {
        field: "Client ID",
        title: "Client ID",
        className: "text-left",
        percentage: 10,
      },
    ];
    this.clientModal = ref(false);
  }
}
