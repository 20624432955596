<!-- @format -->

<template>
  <span v-if="showChatbox" class="fixed-icon" @click="closeChatbox">
    <i class="fas fa-robot"></i>
  </span>
  <div
    class="chatbox"
    :class="showChatbox ? 'expanded_chatbox' : ''"
    :style="showChatbox ? { width: chatBoxWidth + '%' } : {}"
    @mouseover="expandChatbox"
    @mouseleave="collapseChatbox"
    ref="chatbox"
  >
    <div v-if="showChatbox" @mousedown="startResizing" class="resizer">
      <div class="resizer-header"></div>
      <div class="resizer-body"></div>
    </div>
    <div class="w-100">
      <div class="chat-header" @click="openChatbox()">
        <span v-if="!showChatbox" class="icon">
          <i class="fas fa-robot"></i>
        </span>
        <span class="h4 text-dark custom-heading" v-else>
          🌙 Luna’s Corner 🌟
        </span>
        <span v-if="!showChatbox" class="header-text">
          how can i help you?
        </span>
        <span v-else class="icon mb-2" @click.prevent.stop="closeChatbox">
          <i class="fa-solid fa-xmark"></i>
        </span>
      </div>
      <div v-if="showChatbox" class="chat-content">
        <div ref="msgContainer" class="message-container">
          <div v-for="message in messages" :key="message.id" class="message">
            <PtmChatMessage
              @msgLiked="handleMsgLike"
              @msgDisliked="handleMsgDislike"
              @feedbackReverted="handleFeedbackRevert"
              :message="message"
              :loading="loading"
            />
          </div>
        </div>
        <div class="message-input">
          <input
            id="msgInput"
            v-model="newMessage"
            @keyup.enter="sendMessage"
            placeholder="Type your message"
            :disabled="loading"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChatBot from "@/services/api/chatbot/chatbot";
import PtmChatMessage from "./PtmChatMessage.vue";
import uuid4 from "uuid4";
export default {
  data() {
    return {
      showChatbox: false,
      chatDetail: false,
      loading: false,
      messageType: {
        question: "question",
        answer: "answer",
        skeleton: "skeleton",
      },
      currentConversationContext: "",
      messages: [
        {
          id: 1,
          originalText:
            "🌙 Welcome to Luna's corner! 🌟 Here to help with anything you need. I’m still learning, so thanks for bearing with any hiccups. How can I assist you today?",
          text: [
            "🌙 Welcome to Luna's corner! 🌟 Here to help with anything you need. I’m still learning, so thanks for bearing with any hiccups. How can I assist you today?",
          ],
          type: "answer",
          time: new Date(),
          showFeedbackButtons: false,
        },
      ],
      newMessage: "",
      isResizing: false,
      chatBoxWidth: 20,
      isMobileView: window.innerWidth <= 1000,
      chatSessionId: uuid4(),
      greetings: [
        "Hello",
        "Hey there",
        "Anyone there",
        "hello",
        "hey there",
        "anyone there",
        "hey",
        "hi",
        "hi there",
        "how are you?",
        "i want to ask a question",
        "can i ask something?",
        "greetings!",
        "hiya!",
        "hello, bot!",
        "good morning!",
        "good afternoon!",
        "good evening!",
        "could you help me out?",
        "howdy!",
        "what's happening?",
        "hey, can we chat?",
        "hello, friend!",
        "ready for a question?",
        "good day!",
      ],
    };
  },
  components: {
    PtmChatMessage,
  },
  methods: {
    openChatbox() {
      this.showChatbox = true;
    },
    closeChatbox() {
      this.showChatbox = false;
    },
    isGreeting(sentence) {
      return this.greetings.includes(sentence.toLowerCase());
    },
    sendMessage() {
      if (this.newMessage.trim() !== "") {
        const messageContainer = this.$refs.msgContainer;
        if (!this.isGreeting(this.newMessage.trim())) {
          this.storeMessage(this.newMessage, this.messageType.question);
          this.loading = true;
          this.storeMessage("", this.messageType.skeleton);
          const body = {
            text: this.newMessage.trim(),
            sessionId: this.chatSessionId,
            sessionState: {
              sessionAttributes: {
                conversationContext: this.currentConversationContext,
                conversationHistory: this.getChatHistory(),
              },
              intent: {
                name: "FallbackIntent",
                state: "Fulfilled",
              },
            },
          };
          const url = "bot";
          try {
            ChatBot.postQuestion(url, body)
              .then((res) => {
                const messageData = this.extractData(res.data);
                const answer = messageData.messages[0].content;
                this.currentConversationContext =
                  messageData.sessionAttributes.conversationContext;
                this.messages.pop();
                this.loading = false;
                this.storeMessage(answer, this.messageType.answer);
                setTimeout(() => {
                  messageContainer.scrollTop = messageContainer.scrollHeight;
                }, 50);
              })
              .catch((err) => {
                console.error("chatbot api err : ", err);
                this.messages.pop();
                this.loading = false;
                this.storeMessage(
                  [
                    "I can only answer questions regarding tax and payroll. Please try again.",
                  ],
                  this.messageType.answer
                );
              });
          } catch (e) {
            console.error("chatbot api consumer error : ", e);
          }
          this.newMessage = "";
          setTimeout(() => {
            messageContainer.scrollTop = messageContainer.scrollHeight;
          }, 10);
        } else {
          this.storeMessage(this.newMessage, this.messageType.question);
          this.storeMessage(
            ["Greetings! - Luna, How can I assist you today?"],
            this.messageType.answer,
            false
          );
          this.newMessage = "";
          setTimeout(() => {
            messageContainer.scrollTop = messageContainer.scrollHeight;
          }, 10);
        }
      }
    },
    sendFeedback() {
      const body = {
        text: "",
        sessionId: this.chatSessionId,
        sessionState: {
          sessionAttributes: {
            conversationContext: this.currentConversationContext,
            conversationHistory: this.getChatHistory(),
          },
          intent: {
            name: "FallbackIntent",
            state: "Fulfilled",
          },
        },
      };
      const url = "bot";
      try {
        ChatBot.postQuestion(url, body)
          .then((res) => {
            const messageData = this.extractData(res.data);
          })
          .catch((err) => {
            console.error("chatbot api err : ", err);
          });
      } catch (e) {
        console.error("chatbot api consumer error : ", e);
      }
    },
    storeMessage(text, type, showFeedbackButton = true) {
      this.messages.push({
        id: this.messages.length + 1,
        originalText: Array.isArray(text) ? text[0] : text,
        text: Array.isArray(text) ? text : this.formatAnswer(text),
        type: type,
        time: new Date().toISOString(),
        showFeedbackButtons: this.isAnswer(type) && showFeedbackButton,
      });
    },
    isAnswer(type) {
      if (type === this.messageType.answer) {
        return true;
      }
      return false;
    },
    extractData(dataSource) {
      const messageStartStr = '"body": "';
      try {
        const bodyIndex =
          dataSource.indexOf(messageStartStr) + messageStartStr.length;
        return JSON.parse(
          dataSource.substring(bodyIndex, dataSource.length - 4)
        );
      } catch (error) {
        console.error("Error extracting Data:", error);
        return null;
      }
    },
    formatAnswer(answer) {
      const placeHolder = "@~%!$-";
      return answer
        .replace(/\n\n+/g, placeHolder)
        .replace(/\n/g, " ")
        .split(placeHolder);
    },
    startResizing() {
      this.isResizing = true;
      this.startX = event.pageX;
      document.addEventListener("mousemove", this.handleMouseMove);
      document.addEventListener("mouseup", this.handleMouseUp);
    },
    handleMouseMove(event) {
      if (this.isResizing) {
        const delta = event.pageX - this.startX; // changed width
        const chatBox = this.$refs.chatbox;
        const chatBoxWidth = chatBox.offsetWidth;
        const changedWidth = (delta / chatBoxWidth) * 100;
        this.startX = event.pageX;
        this.chatBoxWidth = this.chatBoxWidth - changedWidth;
        const chatBoxMaxWidth = Number(
          window
            .getComputedStyle(chatBox)
            .getPropertyValue("max-width")
            .split("%")[0]
        );
        const chatBoxMinWidth = Number(
          window
            .getComputedStyle(chatBox)
            .getPropertyValue("min-width")
            .split("%")[0]
        );
        if (this.chatBoxWidth > chatBoxMaxWidth) {
          this.chatBoxWidth = chatBoxMaxWidth;
        } else if (this.chatBoxWidth < chatBoxMinWidth) {
          this.chatBoxWidth = chatBoxMinWidth;
        }
      }
    },
    handleMouseUp() {
      if (this.isResizing) {
        this.isResizing = false;
        document.removeEventListener("mousemove", this.handleMouseMove);
        document.removeEventListener("mouseup", this.handleMouseUp);
      }
    },
    resize() {
      this.isMobileView = window.innerWidth <= 1000;
    },
    getChatHistory() {
      const history = [];
      for (let index = 1; index < this.messages.length; index++) {
        let chunk = {};
        if (
          this.messages[index].type === "question" &&
          !this.isGreeting(this.messages[index].originalText.toLowerCase())
        ) {
          chunk.user_input = this.messages[index].originalText;
          chunk.bot_response = this.messages[index + 1].originalText;
          chunk.timestamp = this.messages[index + 1].time;
          chunk.feedback = this.messages[index + 1].liked
            ? 1
            : this.messages[index + 1].disliked
            ? 0
            : undefined;
          if (chunk.bot_response) {
            history.push(chunk);
          }
        }
      }
      return history;
    },
    handleMsgLike(event) {
      this.messages.forEach((element) => {
        if (element.id === event.msgID) {
          element.liked = true;
          this.sendFeedback();
        }
      });
    },
    handleMsgDislike(event) {
      this.messages.forEach((element) => {
        if (element.id === event.msgID) {
          element.disliked = true;
          this.sendFeedback();
        }
      });
    },
    handleFeedbackRevert(event) {
      this.messages.forEach((element) => {
        if (element.id === event.msgID) {
          element.liked = undefined;
          element.disliked = undefined;
          this.sendFeedback();
        }
      });
    },
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.resize();
    });
  },
};
</script>

<style scoped>
html {
  scroll-behavior: smooth;
}
.chatbox {
  display: flex;
  position: fixed;
  bottom: 1.875rem;
  right: 1.875rem;
  width: 3.75rem;
  height: 3.75rem;
  transition: width 0.3s ease, height 0.3s ease;
  box-shadow: rgba(0, 0, 0, 0.25) 0rem 0.825rem 1.75rem,
    rgba(0, 0, 0, 0.22) 0rem 0.625rem 0.625rem;
  border-radius: 0.5rem;
  overflow: hidden;
  z-index: 1100;
}
.chatbox:hover {
  width: 15.625rem;
}
.expanded_chatbox {
  resize: none;
  max-width: 70% !important;
  min-width: 20% !important;
  height: 34.375rem !important;
}

@media screen and (max-width: 81.25rem) {
  .expanded_chatbox {
    max-width: 80% !important;
    min-width: 25% !important;
  }
}
@media screen and (max-width: 62.5rem) {
  .expanded_chatbox {
    min-width: 35% !important;
  }
}
@media screen and (max-width: 53.125rem) {
  .expanded_chatbox {
    max-width: 85% !important;
    min-width: 45% !important;
  }
}
@media screen and (max-width: 37.5rem) {
  .expanded_chatbox {
    min-width: 70% !important;
  }
}

.expanded_chatbox .chat-header {
  height: 12%;
  background-color: #f5f5f5;
  color: #003d60;
  cursor: default;
}

.chat-header {
  background-color: #00bcd4;
  color: white;
  padding: 0.625rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.custom-heading {
  font-weight: 600;
}
.header-text {
  display: none;
}

.chat-header:hover .header-text {
  color: white;
  display: block !important;
}

.icon {
  font-size: 1.5em;
  cursor: pointer;
  padding-left: 0.25rem;
  padding-bottom: 0.313rem;
}

.fixed-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  height: 3.75rem;
  width: 3.75rem;
  border-radius: 50%;
  background: #00bcd4;
  font-size: 1.5em;
  position: fixed;
  bottom: 0.125rem;
  right: 0.313rem;
  z-index: 1101;
  cursor: pointer;
  transition: all 0.3s ease;
}
.icon .fa-robot {
  font-size: 1.5rem;
}
.fixed-icon .fa-robot {
  font-size: 1.5rem;
}

.chat-content {
  position: absolute;
  width: 100%;
  background-color: #fff;
  height: 95%;
  overflow-y: auto;
}

.message-container {
  height: 80%;
  position: relative;
  padding: 0.625rem;
  top: 0%;
  overflow-y: auto;
}

::-webkit-scrollbar {
  width: 0.25rem;
  border-radius: 0.313rem;
  cursor: pointer;
}
::-webkit-scrollbar :hover {
  width: 0.375rem !important;
  border-radius: 0.313rem;
}

::-webkit-scrollbar-thumb {
  background-color: #3498db;
  border-radius: 0.313rem;
}

.chat-header {
  position: relative;
  top: 0%;
}
.message {
  padding: 0.5rem 0.5rem 0.5rem 0rem;
  margin-bottom: 0.625rem;
  border-radius: 0.313rem;
  display: flex;
  flex-direction: column;
}

.message-input {
  position: relative;
  bottom: 0rem;
  width: 100%;
  padding-top: 0.625rem;
  background: #f5f5f5;
}

.message-input input {
  width: 98%;
  padding: 0.5rem;
  border: 0.063rem solid #ccc;
  border-radius: 0.313rem;
  box-sizing: border-box;
  outline: none;
  color: rgba(0, 0, 0, 0.8);
}

.resizer {
  width: 0.1875rem;
  height: 100%;
  cursor: ew-resize;
  background: #f9f9f9;
}
.resizer .resizer-header {
  height: 12%;
  background: #f5f5f5;
}
.resizer .resizer-body {
  height: 88%;
}
</style>
