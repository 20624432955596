import { authGuard } from "@/guards/auth";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "SignIn",
    meta: { authRequired: false },
    component: () => import("../views/SignIn.vue"),
  },
  {
    path: "/callback",
    name: "Callback",
    meta: { authRequired: false },
    component: () => import("../views/CallbackView.vue"),
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    meta: { authRequired: true },
    component: () => import("@/views/DashboardView.vue"),
  },
  {
    path: "/proclientlist",
    name: "PrClientList",
    meta: { authRequired: true },
    component: () => import("@/components/processor/ProcessorClientList.vue"),
  },
  {
    path: "/taxpackage",
    name: "TaxPackage",
    meta: { authRequired: true },
    component: () => import("../components/taxpackages/PtmTaxPackages.vue"),
  },
  {
    path: "/taxprofile",
    name: "Tax",
    meta: { authRequired: true },
    component: () => import("../components/taxprofile/PtmTaxProfile.vue"),
  },
  {
    path: "/usersetup",
    name: "UserList",
    component: () => import("../components/usersetup/UserListPage.vue"),
    meta: { authRequired: true },
  },
  {
    path: "/usersetup/create",
    name: "UserCreate",
    component: () => import("../components/usersetup/UserCreatePage.vue"),
    meta: { authRequired: true },
  },
  {
    path: "/usersetup/update",
    name: "UserUpdate",
    component: () => import("../components/usersetup/UserUpdatePage.vue"),
    meta: { authRequired: true },
  },
  {
    path: "/listOfProcessorSetup",
    name: "ListOfProcessorSetup",
    component: () =>
      import("../components/processor/ListOfProcessorSetupInTaxPortal.vue"),
    meta: { authRequired: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(authGuard);

export default router;
