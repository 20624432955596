import { RouteLocationNormalized, NavigationGuardNext } from "vue-router";
import { useAuthStore } from "../stores/auth";

export function authGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
): Promise<void> | void {
  const authStore = useAuthStore();
  if (!to.meta.authRequired || authStore.isAuthenticated) {
    if (to.matched.length > 0) {
      return next();
    } else {
      return next({ name: "Dashboard" });
    }
  } else {
    return next({ name: "SignIn" });
  }
}
